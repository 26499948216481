import React, { useState } from "react"
import moment from "moment"
import classNames from "classnames"
import { useQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"

import styles from "./layout.module.scss"
import {
  hasSeenNotification,
  setNotificationAsSeen,
} from "./services/notification"
import { GATSBY_PHONE_NUMBER } from "gatsby-env-variables"

const Notification = () => {
  let dateToday = moment().format("YYYY-MM-DD")
  const [notificationHidden, setNotificationHidden] = useState(false)
  const hasSeenNotificationMessage = hasSeenNotification()

  const { loading, error, data } = useQuery(
    gql`
      query TodaysDate($holiday_date: date!, $domain: jsonb) {
        holiday(
          where: {
            holiday_date: { _eq: $holiday_date }
            domains: { _contains: $domain }
          }
        ) {
          name
          holiday_date
          short_message
        }
      }
    `,
    {
      variables: {
        holiday_date: dateToday,
        domain: process.env.GATSBY_AWS_S3_BUCKET_NAME,
      },
    }
  )

  if (loading) return null
  if (error) console.log(error)

  let notificationMessage =
    data?.holiday === undefined || data?.holiday?.length === 0
      ? null
      : data?.holiday[0]?.short_message

  notificationMessage = (
    <span className={classNames(styles["notificationMessage"])}>
      {notificationMessage || ""} All enrollments and requests are confirmed via
      email/SMS within one working day. If you do not receive a confirmation or
      have other inquires, contact us at{" "}
      <a href={`tel:${GATSBY_PHONE_NUMBER}`}>{GATSBY_PHONE_NUMBER}</a>.
    </span>
  )

  if (notificationMessage && !hasSeenNotificationMessage)
    return (
      <div
        className={classNames(
          "notification mb-0 is-warning",
          {
            "is-hidden": notificationHidden,
          },
          styles["notification"]
        )}
      >
        <button
          className="delete"
          onClick={() => {
            setNotificationHidden(true)
            setNotificationAsSeen()
          }}
        ></button>
        <div className="has-text-centered">{notificationMessage}</div>
      </div>
    )

  return null
}

export default Notification
