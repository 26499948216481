import React from "react"
import Img from "gatsby-image"
import styles from "../layout.module.scss"
import classNames from "classnames"
import { Link } from "gatsby"
import { useNavbarImages } from "../hooks/useNavbarImages"
import { hasDoctorRole } from "../../Auth/services/authUser"


const AuthActions = ({ user }) => {
  const data = useNavbarImages()

  const logoLong = data.logoLong.childImageSharp.fixed
  const logoCircle = data.logoCircle.childImageSharp.fixed

  return (
    <Link to={(hasDoctorRole()?.active) ? "/doctor" : "/"}>
      <div className={classNames("ml-1", styles["navbar__brandLogos"])}>
        <Img fixed={logoLong} className="is-hidden-mobile" />
        <Img fixed={logoCircle} className="is-hidden-tablet" />
      </div>
    </Link>
  )
}

export default AuthActions
