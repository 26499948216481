import { useStaticQuery, graphql } from "gatsby"

const useFooterImages = () => {
  const data = useStaticQuery(graphql`
    {
      medgrocerLogo: file(relativePath: { eq: "logos/medgrocer-long.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      novonordiskLogo: file(
        relativePath: { eq: "logos/novonordisk-long__color.png" }
      ) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const medgrocerLogo = data.medgrocerLogo.childImageSharp.fixed
  const novonordiskLogo = data.novonordiskLogo.childImageSharp.fixed

  return { medgrocerLogo, novonordiskLogo }
}

export default useFooterImages
