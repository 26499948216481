import React, { Fragment } from "react"
import Img from "gatsby-image"

import useFooterImages from "../hooks/useFooterImages"

const AboutNovoNordisk = () => {
  const { novonordiskLogo } = useFooterImages()
  const yearNow = new Date(Date.now()).getFullYear()
  return (
    <Fragment>
      <Img fixed={novonordiskLogo} alt="Novo Nordisk" />
      <div className="help">
        <p className="help has-text-weight-bold">
          Novo Nordisk Pharmaceutical Philippines, Inc.
        </p>
        <p className="help">
          21<sup>st</sup> Floor, Twenty-Four Seven McKinley Building <br />
          24<sup>th</sup> Street cor. 7<sup>th</sup> Avenue <br />
          Bonifacio Global City, 1634 Taguig City, Philippines
          <br />
          Tel No. (+632) 8 234-3500; Fax No. (+632) 8 234-3590
          <br />
          Email: NPPISafety@novonordisk.com <br />
          Website: www.novonordisk.com
        </p>
        <p className="help mt-1">© {yearNow}</p>
        <p className="help">Novo Nordisk A/S</p>
        <p className="help">PH21OZM00008</p>
        <p className="help">January 2021</p>
      </div>
    </Fragment>
  )
}

export default AboutNovoNordisk
