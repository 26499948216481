import React, { Fragment } from "react"
import { Link, navigate } from "gatsby"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserMd } from "@fortawesome/free-solid-svg-icons"

import styles from "../layout.module.scss"

const AuthActions = ({ user, handleSignOut }) => {
  if (user?.authUid)
    return (
      <Fragment>
        <div className="navbar-item">
          <button
            className="button is-primary is-fullwidth mt-1 is-centered is-hidden-desktop"
            onClick={handleSignOut}
          >
            Sign Out
          </button>
        </div>
        <div className="navbar-item has-dropdown is-hoverable is-hidden-touch">
          <span className="navbar-link">
            <FontAwesomeIcon icon={faUserMd} />
            <span className="ml-1">
              {" "}
              {`${user?.firstName} ${user?.lastName}`}
            </span>
          </span>
          <div className="navbar-dropdown">
            <span
              aria-hidden="true"
              className={classNames(
                "navbar-item is-clickable",
                styles["navbar__item"]
              )}
              onClick={() => navigate("/profile")}
            >
              Profile
            </span>
            <span
              aria-hidden="true"
              className={classNames(
                "navbar-item is-clickable",
                styles["navbar__item"]
              )}
              onClick={() => navigate("/sign-out")}
            >
              Sign Out
            </span>
          </div>
        </div>
      </Fragment>
    )
  return (
    <Link className={classNames(styles["signInButton"])} to="/sign-in">
      <button
        className={classNames(
          "navbar-item is-hidden-mobile button is-transparent is-outlined"
        )}
      >
        Sign in
      </button>
    </Link>
  )
}

export default AuthActions
