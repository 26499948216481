import {
  faEnvelope,
  faFileAlt,
  faNotesMedical,
  faPrescription,
  faQuestion,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons"

import { GATSBY_FIREBASE_DOCTOR_ROLE_ID } from "gatsby-env-variables"

export const navbarItems = [
  {
    name: "Issue ePrescription",
    link: "/issue-erx/patient-details",
    icon: faPrescription,
    isHiddenInDesktop: false,
    roles: [GATSBY_FIREBASE_DOCTOR_ROLE_ID],
  },
  {
    name: "View Patients",
    link: "/patients",
    icon: faNotesMedical,
    isHiddenInDesktop: false,
    roles: [GATSBY_FIREBASE_DOCTOR_ROLE_ID],
  },
  {
    name: "FAQs",
    link: "/help-center",
    icon: faQuestion,
    isHiddenInDesktop: false,
  },
  {
    name: "Contact Us",
    link: "/help-center#contact-us",
    icon: faEnvelope,
    isHiddenInDesktop: true,
  },
  {
    name: "Terms and Conditions",
    link: "/terms-and-conditions",
    icon: faFileAlt,
    isHiddenInDesktop: true,
  },
  {
    name: "Privacy Policy",
    link: "/privacy-policy",
    icon: faShieldAlt,
    isHiddenInDesktop: true,
  },
]
