/*
 * MedGrocer logo only
 */
import React, { useEffect, useState, Fragment } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"

import NavbarLink from "./Navbar/NavbarLink"
import AuthActions from "./Navbar/AuthActions"
import AuthActionsIcon from "./Navbar/AuthActionsIcon"
import AuthActionsMobile from "./Navbar/AuthActionsMobile"
import MobilePersonalDetails from "./Navbar/MobilePersonalDetails"

import styles from "./layout.module.scss"
import { navbarItems } from "./utils/navbarItems"
import { getAuthUser } from "../Auth/services/authUser"
// import { useNavbarImages } from "./hooks/useNavbarImages"
import { handleSignOut } from "../Auth/services/authActions"

const Navbar = () => {
  // const data = useNavbarImages()

  const [user, setUser] = useState({ roles: [] })
  const [isMenuActive, setMenuActive] = useState(false)

  useEffect(() => {
    const userData = getAuthUser()?.userData
    setUser(userData)
  }, [])

  const handleMenuActive = () => setMenuActive(!isMenuActive)

  return (
    <Fragment>
      <nav className="navbar is-fixed-top is-primary">
        <div className={classNames(styles["navbar__brand"])}>
          <AuthActionsIcon user={user}></AuthActionsIcon>
          <div className={styles["navbar__burgerContainer"]}>
            <AuthActionsMobile user={user} isMenuActive={isMenuActive} />
            <span
              role="button"
              onClick={handleMenuActive}
              onKeyDown={event => {
                if (handleMenuActive && event.key === "Enter")
                  handleMenuActive()
              }}
              aria-label="menu"
              aria-expanded="false"
              className="icon is-clickable"
              tabIndex={0}
            >
              <FontAwesomeIcon icon={isMenuActive ? faTimes : faBars} />
            </span>
          </div>
        </div>
        <div
          className={classNames("navbar-menu", styles["navbar__menu"], {
            "is-active": isMenuActive,
          })}
        >
          <div className="navbar-end mr-1">
            <MobilePersonalDetails
              user={user}
              handleMenuActive={handleMenuActive}
            />
            {navbarItems.map(item => (
              <NavbarLink
                navbarItem={item}
                user={user}
                setMenuActive={setMenuActive}
              />
            ))}
            <AuthActions user={user} handleSignOut={handleSignOut} />
          </div>
        </div>
      </nav>
      <div
        role="menu"
        tabIndex={0}
        className={classNames({
          [styles["navbar__backgroundIsActive"]]: isMenuActive,
        })}
        onClick={() => setMenuActive(false)}
        onKeyDown={event => {
          if (event.key === "Enter") setMenuActive(false)
        }}
      />
    </Fragment>
  )
}

export default Navbar
