import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import useFooterImages from "../hooks/useFooterImages"

const AboutMedGrocer = () => {
  const { medgrocerLogo } = useFooterImages()
  const currentYear = new Date().getFullYear()

  return (
    <div className="mb-2">
      <Link to="/">
        <Img fixed={medgrocerLogo} />
      </Link>
      <p className="help">
        MedGrocer's digitally-enabled online pharmacy, corporate clinics, and
        patient programs empower our customers to get their medicines
        conveniently, cost-effectively, and intelligently.
      </p>

      <p className="help pt-1">
        We serve over 500,000 patients either directly or through partnerships
        with employers and pharmaceutical companies.
      </p>
      <p className="help pt-1">
        © {currentYear}, MedGrocer · MedGrocer (MG Health Solutions, Inc.)
      </p>
    </div>
  )
}

export default AboutMedGrocer
