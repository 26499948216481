import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  GATSBY_AWS_S3_BUCKET_NAME,
  GATSBY_FIREBASE_DOCTOR_ROLE_ID,
} from "gatsby-env-variables"

import styles from "../layout.module.scss"

const NavbarLink = ({ navbarItem, user, setMenuActive }) => {
  let hasDoctorRole =
    user?.roles?.filter(
      roleObject =>
        roleObject.subdomain === GATSBY_AWS_S3_BUCKET_NAME &&
        roleObject.role === GATSBY_FIREBASE_DOCTOR_ROLE_ID &&
        roleObject.active
    ).length > 0

  if (
    !navbarItem.roles ||
    (hasDoctorRole && navbarItem.roles.includes(GATSBY_FIREBASE_DOCTOR_ROLE_ID))
  )
    return (
      <Link
        to={navbarItem.link}
        className={navbarItem.class}
        onClick={() => setMenuActive(false)}
      >
        <span
          className={classNames("navbar-item p-1", styles["navbar__menuItem"], {
            "is-hidden-desktop": navbarItem.isHiddenInDesktop,
          })}
        >
          <FontAwesomeIcon
            icon={navbarItem.icon}
            className="is-hidden-desktop icon mr-1 has-text-primary"
          />
          {navbarItem.name}
        </span>
      </Link>
    )

  return null
}

export default NavbarLink
