import { GATSBY_FIREBASE_PROJECT_ID } from "gatsby-env-variables"
import axios from "axios"
import { isBrowser } from "../general"

export const firebaseApi = props => {
  if (isBrowser()) {
    let api = axios.create({
      baseURL: `https://firestore.googleapis.com/v1/projects/${GATSBY_FIREBASE_PROJECT_ID}/databases/(default)/documents/`,
      headers: {
        Authorization: "Bearer " + (props.accessToken || ""),
        "Content-Type": "application/json",
      },
    })

    return api
  }
  return null
}
