import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import { faUserMd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const AuthActionsMobile = ({ user, isMenuActive }) => {
  if (user?.authUid)
    return (
      <Link
        className={classNames(
          "navbar-item mr-1 px-1 has-text-centered button is-primary is-inverted is-outlined",
          {
            "is-hidden": isMenuActive,
          }
        )}
        to="/profile"
      >
        <FontAwesomeIcon icon={faUserMd} />
        <span className="ml-1">{`${user?.firstName} ${user?.lastName[0]}.`}</span>
      </Link>
    )
  return (
    <Link
      className={classNames(
        "navbar-item button mr-1 px-1 mb-0 is-transparent has-text-primary is-inverted is-outlined",
        {
          "is-hidden": isMenuActive,
        }
      )}
      to="/sign-in"
    >
      Sign in
    </Link>
  )
}

export default AuthActionsMobile
