export const isObjectEmpty = object => {
  for (var i in object) return false
  return true
}

export const isBrowser = () => typeof window !== "undefined"

export const capitalize = string => {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
}
