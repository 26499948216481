import { camelizeKeys } from "humps"
import { isBrowser } from "../../../services/general"
import {
  GATSBY_AWS_S3_BUCKET_NAME,
  GATSBY_FIREBASE_DOCTOR_ROLE_ID,
} from "gatsby-env-variables"

export const hasAuthUser = () => {
  if (isBrowser()) {
    const authUser = Object.keys(window.sessionStorage).filter(item =>
      item.startsWith("firebase:authUser")
    )[0]

    return authUser
  }
}

export const getAuthUser = () => {
  if (isBrowser()) {
    let authUser = Object.keys(window.sessionStorage).filter(item =>
      item.startsWith("firebase:authUser")
    )[0]
    authUser = JSON.parse(sessionStorage.getItem(authUser))
    let userData = camelizeKeys(JSON.parse(sessionStorage.getItem("userData")))
    let addresses = JSON.parse(sessionStorage.getItem("addresses"))

    return { ...authUser, userData, addresses }
  }
}

export const hasDoctorRole = () => {
  if (isBrowser()) {
    let userData = getAuthUser()?.userData
    let userRoles = userData?.roles || []
    let doctorRole = userRoles.find(
      roleObject =>
        roleObject?.subdomain === GATSBY_AWS_S3_BUCKET_NAME &&
        roleObject?.role === GATSBY_FIREBASE_DOCTOR_ROLE_ID
    )
    return doctorRole
  }
  return false
}
