import firebase from "firebase"
import FireStoreParser from "firestore-parser"
import { navigate } from "gatsby"

import { isBrowser } from "services/general"
import { generateJWT } from "services/jwt"
import { firebaseApi } from "services/firebase/firebaseApi"
import { hasAuthUser, getAuthUser } from "./authUser"

import {
  GATSBY_AWS_S3_BUCKET_NAME,
  GATSBY_FIREBASE_DOCTOR_ROLE_ID,
  GATSBY_FIREBASE_PROJECT_ID,
  GATSBY_LOGIN_REDIRECT,
} from "gatsby-env-variables"

export const getUserData = async ({ authUid }) => {
  const filteredUsers = await firebase
    .firestore()
    .collection("users")
    .where("authUid", "==", authUid)
    .get()
  let userData
  filteredUsers.forEach(user => {
    userData = { ...user.data(), id: user.id }
  })

  return userData
}

export const getUserAddresses = async ({ addressesId }) => {
  let filteredAddress = await firebase
    .firestore()
    .collection("addresses")
    .doc(addressesId || " ")
    .get()
  if (filteredAddress.data())
    filteredAddress = {
      ...filteredAddress.data(),
      id: filteredAddress.id,
    }
  else filteredAddress = {}

  return filteredAddress
}

export const handleEmailLogin = config => {
  let {
    values,
    setErrors,
    resetForm,
    callback,
    redirectUrl,
    errorCallback,
  } = config

  firebase
    .auth()
    .signInWithEmailAndPassword(values.email, values.password)
    .then(async response => {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      const authUid = response?.user?.uid
      let userData = await getUserData({ authUid })
      let addressesId = userData?.addresses
      let userAddresses = await getUserAddresses({ addressesId })

      if (isBrowser()) {
        sessionStorage.setItem(
          "userData",
          JSON.stringify({
            ...userData,
          })
        )
        sessionStorage.setItem("addresses", JSON.stringify(userAddresses))
      }

      if (callback) callback()
      resetForm()
      let roles =
        userData?.roles?.filter(
          role =>
            role?.subdomain === GATSBY_AWS_S3_BUCKET_NAME &&
            role?.role === GATSBY_FIREBASE_DOCTOR_ROLE_ID
        ) || []
      if (roles?.length > 0) {
        if (roles[0].active) return navigate(redirectUrl || "/doctor")
        else return navigate("/profile")
      } else return navigate("/doctor/enroll")
    })
    .catch(error => {
      if (errorCallback) errorCallback()
      setErrors({ login: { code: error.code, message: error.message } })
    })
}

export const handlePasswordUpdate = async ({
  values,
  setErrors,
  callback,
  errorCallback,
}) => {
  let currentUserEmail = getAuthUser()?.email

  try {
    await firebase
      .auth()
      .signInWithEmailAndPassword(currentUserEmail, values?.oldPassword)
    let user = firebase.auth().currentUser
    await user.updatePassword(values?.password)

    if (callback) callback()
  } catch (error) {
    if (errorCallback) errorCallback()
    setErrors({ updatePassword: { code: error.code, message: error.message } })
  }
}

export const handleSignOut = ({ redirect = true }) => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      if (isBrowser()) {
        sessionStorage.removeItem("userData")
        sessionStorage.removeItem("addresses")
      }
      if (redirect) return navigate("/")
    })
    .catch(error => {
      console.error("Sign Out Error", error)
    })
}

export const handleForgotPassword = ({ email, callback, errorCallback }) => {
  var actionCodeSettings = {
    url: GATSBY_LOGIN_REDIRECT,
  }

  firebase
    .auth()
    .sendPasswordResetEmail(email, actionCodeSettings)
    .then(() => {
      callback()
    })
    .catch(error => {
      console.log(error.message)
      if (errorCallback) errorCallback(error)
    })
}

export const checkIfEmailAlreadyExists = async ({ email }) => {
  return firebase.auth().fetchSignInMethodsForEmail(email)
}

export const checkIfUserAlreadyExists = async ({ email }) => {
  if (hasAuthUser()) {
    let filteredUsers = await firebase
      .firestore()
      .collection("users")
      .where("email", "==", email)
      .get()

    let userDocuments = []

    filteredUsers.forEach(user =>
      userDocuments.push({ ...user.data(), id: user.id })
    )

    let userDocument = userDocuments.find(user => user.email === email)

    return userDocument
  } else {
    const jwtObject = await generateJWT()
    const accessToken = jwtObject?.data?.access_token
    let request = await firebaseApi({ accessToken: accessToken }).post(
      `:runQuery`,
      {
        structuredQuery: {
          select: {
            fields: [],
          },
          from: [
            {
              collectionId: "users",
            },
          ],
          where: {
            fieldFilter: {
              field: {
                fieldPath: "email",
              },
              op: "EQUAL",
              value: {
                stringValue: email,
              },
            },
          },
        },
      }
    )
    let id = request?.data[0]?.document?.name || ""
    if (id) {
      id = id.split("/")
      id = id[id.length - 1]
      request = await firebaseApi({ accessToken: accessToken }).get(
        `users/${id}`
      )
      const userData = FireStoreParser(request?.data?.fields)
      return { ...userData, id: id }
    } else return {}
  }
}

export const handleExistingEmailSignUp = config => {
  let { values, setErrors, handleSignUpSuccess, userDocument } = config
  let userRoles =
    userDocument?.roles?.filter(
      role =>
        role.subdomain !== GATSBY_AWS_S3_BUCKET_NAME &&
        role.role !== GATSBY_FIREBASE_DOCTOR_ROLE_ID
    ) || []

  firebase
    .auth()
    .createUserWithEmailAndPassword(values.email, values.password)
    .then(async response => {
      let authUid = response.user.uid

      firebase
        .firestore()
        .collection("users")
        .doc(userDocument?.id)
        .update({
          authUid,
          roles: [
            ...userRoles,
            {
              projectId: GATSBY_FIREBASE_PROJECT_ID,
              subdomain: GATSBY_AWS_S3_BUCKET_NAME,
              role: GATSBY_FIREBASE_DOCTOR_ROLE_ID,
            },
          ],
        })
        .then(() => {
          handleSignOut({ redirect: false })
          if (handleSignUpSuccess) handleSignUpSuccess()
        })
    })
    .catch(error =>
      setErrors({ signup: { code: error.code, message: error.message } })
    )
}
