import { useStaticQuery, graphql } from "gatsby"

const ALL_NAVBAR_IMAGES = graphql`
  {
    logoLong: file(
      relativePath: { eq: "logos/medgrocer-novonordisk-logo__long-white.png" }
    ) {
      childImageSharp {
        fixed(height: 25) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    logoCircle: file(
      relativePath: { eq: "logos/medgrocer-novonordisk-logo__short-white.png" }
    ) {
      childImageSharp {
        fixed(height: 25) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export const useNavbarImages = () => {
  const data = useStaticQuery(ALL_NAVBAR_IMAGES)

  return data
}
